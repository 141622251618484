
// Mix of custom and Bootstrap variables

// INO UI colors
$ino-blue: #012558;
$ino-blue_shade: #1f488a;
$up-color: #008A1C;
$down-color: #bd0000;
$neutral-color: #c1c5cb;
$mc-blue: #517bcd;
$light-gray: #e0e2e5;
$medium-gray: #707b8c;

// links
$link-color: #0246a6;
$link-hover: #025bd9;

// Bootstrap color variables
$primary: $ino-blue;
$secondary: $ino-blue_shade;
$success: $up-color;
$danger: $down-color;

// header
$header-bg-color: $ino-blue;
$header-search-btn-color: $ino-blue_shade;
$navbar-submenu-hover-bg: #10386e;

// forms, tables
$table-alt-row-bg: #f2f4f7;
$lead-form-bg-color: #cceeff;

// Badges
$badge-font-size:                   100%;
$badge-font-weight:                 normal;

// Bootstrap breakpoints
// xs < 576
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// addtl. classes
.badge-up {
  color: white;
  background-color: $up-color;
}
.badge-down {
  color: white;
  background-color: $down-color;
}