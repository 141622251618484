#scale {
  margin-top: 20px;
  margin-bottom: 30px;
  // max-width: 309px;
  width:100%;
  img {
    // max-width: 309px;
    width:100%;
  }
}
/* scoring */
#score {
  margin-top:5px;
  font-size: 24px;
  line-height: 0.5;
  text-align: left;
  span.operator {
    font-size: 18px;
    vertical-align: 10%;
  }
}
#score.up {
  color: #399c33;
}
#score.down {
  color: #bd0000;
}
#score.score-90.down {  padding-left:5%;}
#score.score-85.down {  padding-left:10%;}
#score.score-80.down {  padding-left:15%;}
#score.score-75.down {  padding-left:20%;}
#score.score-70.down {  padding-left:25%;}
#score.score-65.down {  padding-left:30%;}
#score.score-60.down {  padding-left:35%;}
#score.score-55.down {  padding-left:40%;}
#score.score-50      {  padding-left:45%; color: #000;}
#score.score-55.up   {  padding-left:50%;}
#score.score-60.up   {  padding-left:55%;}
#score.score-65.up   {  padding-left:60%;}
#score.score-70.up   {  padding-left:65%;}
#score.score-75.up   {  padding-left:70%;}
#score.score-80.up   {  padding-left:75%;}
#score.score-85.up   {  padding-left:80%;}
#score.score-90.up   {  padding-left:85%;}
#score.score-100.up  {  text-align: right;}

span.first {
  letter-spacing: -2px;
}
#labels {
  white-space: nowrap;
  line-height: 0.5;
  span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    color: #333333;
    display: inline-block;
    width:33%;
  }
  span.ctr {
    // width: 50%;
    text-align: center;
  }
  span.last {
    text-align:right;
    // padding-left: 19px;
  }
}
