
// animated transitions
.market-change {
  //background-color: transparent;
  //transition: background-color 0ms ease;
}
.market-change-appear {}
.market-change-appear-active {}

.market-change-enter,
.market-change-enter-active {
  background-color: rgba(255, 255, 115, .5);
  transition: background-color 700ms ease;
}
.market-change-enter-done,
.market-change-exit {
  background-color: transparent;
  transition: background-color 700ms ease;
}
.market-change-exit-active {}
