.blog-content,
.blog-entry {
    img {
        display: block;
        max-width: 100%;
        margin: 1em 0;
        height: auto;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
    }
    .date {
        margin-bottom: .25em;
        color: $medium-gray;
    }
}
.page-ad {
    background-color: $lead-form-bg-color;
    border-radius: $border-radius;

    margin-bottom: 1em;
    padding: 1.25em;

    h2, h3, h4 {
        margin-top: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

.post-wrap {
    margin-bottom: 1em;
    border-bottom: 1px solid $light-gray;
}

iframe {
    width: 100%;
}
