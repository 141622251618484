#analysis-main .analysis-table {
  width: 100%;
  border: 1px solid #ccc;
  th {
    font-weight: normal;
  }

  tfoot {
    border-top: 1px solid #ccc;
  }

  tr.label-block {
    th {
      padding: 8px;
      font-size: 14px;
      background-color: #eee;
      border-right: 1px solid #ccc;
      font-weight: bold;
    }
  }
  tr.content-block {
    th {
      padding: 6px;
      font-size: 14px;
      border-right: 1px solid #ccc;
    }
  }
}
