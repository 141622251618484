.up {
  color: $up-color;
}
.down {
  color: $down-color;
}
.neutral,
.version {
  color: #989aa2;
}

td.rt,
th.rt {
  text-align: right;
}
td.ctr,
th.ctr {
  text-align: center;
}

.greeting-user {
  color: var(--mc-heading);
}