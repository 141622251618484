.portfolio-table {
  .badge {
    width: 100%;
  }
  .align_middle {
    vertical-align: middle;
  }
  small {
    font-size: 100%;
  }
  td {
    padding: .4em .2em;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.portfolio-table.subportfolio-table-rows {
  td {
    max-width: 100px;
  }
}

.portfolio-alerts {
  background-color: #f2f5fa;
  border-color: #5bc0de;
  border-left: 3px solid #517bcd;
  padding: 12px 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  ul {
    padding-left: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }
}

.sub-portfolio-items {
  vertical-align: middle !important;
  .sub-portfolio-title {
    display: inline-block;
    width: 100%;
    color: #000;
    font-size: 16px;
  }
  .editName {
    color: red;
  }
}
