.content {
  margin: auto;
  margin-top: 10%;
}

.loginForm {
  width: 220px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    align-self: flex-end;
  }
  p {
    margin-top: 2.2em;
    align-self: flex-end;
  }
}

.forgotPasswordForm {
  width: 220px;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .email {
    width: 220px;
  }
}

.emailError {
  font-size: 14px;
  color: red;
  position: absolute;
  left: -25px;
  top: 7px;
}
.passwordError {
  color: red;
  font-size: 14px;
  position: absolute;
  left: -25px;
  top: 60px;
}

.logoImg {
  width: 180px;
  height: 180px;
  margin: auto;
}
.no-border {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.no-border:focus {
  outline: 0px !important;
  box-shadow: none !important;
}
.login-alert {
  margin-bottom: 1em 0px;
  height: 20px;
  line-height: 20px;
  padding: 0px 20px;
  width: 30rem;
}
.labelForText {
  width: 220px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

.resetPassword {
  position: absolute;
  left: -30px;

  color: red;
}

.forgot-pass-link {
  font-size: 14px;
}

.back-to-login {
  font-size: 14px;
  button {
    background-color: transparent;
    color: var(--mc-link);
  }
}

.successMessage,
.errorMessage {
  text-transform: capitalize;
}
