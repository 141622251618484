/* various components */
.search-button {
  margin-right: 10px;
  font-size: 24px;
}

.top-lists-select {
  height: 2.1em;
  border-radius: 6px;
  --padding-start: 0.9em;
  --padding-end: 0.9em;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  margin: 4px 2px;
  font-size: 13px;
  letter-spacing: .4px;
}

// type
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.2;
  color: var(--mc-heading);
}
h1 {
  margin-top: 20px;
  font-size: 18px;
}
h2 {
  margin-top: 18px;
  font-size: 18px;
}
h3 {
  font-size: 18px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

body {
  font-size: 15px;
}

.section-heading {
  border-bottom: 2px solid var(--ion-color-light-shade);
  padding-bottom: 5px;
  margin-bottom: 20px;
  margin-top: 40px;
}

// override, especially for "choose portfolio" button
ion-button {
  box-shadow: none;
  --box-shadow: none;
}