.phone-icon,
.phone-num,
.email-icon,
.email-link {
  fill: var(--ion-color-primary);
}
.FB-icon {
  margin: 0 .5em;
  fill: #1877f2;
}
.TW-icon {
  margin: 0 .5em;
  fill: #1d9bf0;
}
.YT-icon {
  margin: 0 .5em;
  fill: #ff0000;
}
