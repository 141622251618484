
.quote-table {
  width: 100%;
  th,
  td {
    padding: .5em;
    font-size: 14px;
    line-height: 1.2em;
    border-bottom: 1px solid #c1c5cb;
  }
  th {
    font-weight: normal;
  }
  td {
    font-weight: bold;
    text-align: right;
  }

  &:first-of-type {
    margin-right: 1em;
  }
}

.quote-table-wrap {
  @include clearfix;
}

.quote-price {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}
.quote-date {
  margin-bottom: 1em;
  text-transform: uppercase;
  font-size: 13px;
}

#quote-below-chart-component {
  margin-bottom: 1em;
}

.choose-portfolio {
  background-color: var(--mc-btn-green);
  border-radius: 6px;
  height: 32px;
  .choose-button {
    font-weight: bold;
  }
}