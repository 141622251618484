/*

React component structure, IDs and classes:

#comp_market-pulse {} // div that gets replaced by React
  #market-pulse.market-pulse {} // div
    .market-pulse__market {} // dl
    #market-indicator {} // div
*/

#market-pulse {
  // white during initial load, don't want a black line to show up while loading
  color: #fff;
  border-bottom: 6px solid;

  &.up {
    border-color: $up-color;
  }
  &.neutral {
    border-color: $neutral-color;
  }
  &.down {
    border-color: $down-color;
  }

  //display: flex;
  //flex-direction: row;
  //flex-wrap: nowrap;
}
// styling
.market-pulse__market {
  margin-bottom: .5em;
  margin-left: .5em;
  margin-right: .5em;
  //flex: 1 1 auto;
  dd {
    margin-bottom: 0;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1.4;

    &.up {
      color: $up-color;
    }
    &.neutral {
      color: #000;
    }
    &.down {
      color: $down-color;
    }
  }
}

#market-pulse {
  .ion-slides {
    height: 100%;
  }
  .swiper-slide {
    align-items: flex-start;
    text-align: left;
    font-size: inherit;
  }
}