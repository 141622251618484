.scan-filters-modal-content {
  top: 3%;
  .scan-filters-modal-title {
    font-weight: bold;
    font-size: 20px;
    padding: 1rem 0;
  }

  ion-grid {
    padding: 1rem;
  }

  ion-row {
    margin-bottom: 1rem;
  }

  ion-label {
    font-weight: bold;
  }

  ion-select {
    width: 100%;
    --padding-start: 0px;
  }

  .apply-button {
    margin-top: 2rem;
    width: 8rem;
  }
}

.scan-filters-button {
  letter-spacing: .4px;
}

.header-table {
  tr {
    th {
      background-color: var(--ion-color-primary);
      color: #ffffff;
    }
  }
  .br_right {
    border-right: 1px solid;
  }

  div.sortable-part {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: transparent;
      color: #fff;
      font-size: 13px;
    }
    button:focus {
      outline: none !important;
    }
  }
}
