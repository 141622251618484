.triangle {
  width: 16px;
  height: 16px;
  margin-right: 1em;
  display: inline-block;
  color: #fff;
  font-size: 9px;
  text-align: center;
  position: relative;
}

.triangle-up {
  background-color: $up-color;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle-down {
  background-color: $down-color;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.triangle-up .text {
  top: 5px;
}

td.no-triangles {
  border: none;
  padding: 0;
}