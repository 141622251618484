.notify-outer ion-card {
  margin: 10px 0px;
}
.notify-box {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.notify-box .group-icon-title {
  flex: 1;
}
.notify-box .group-icon-title ion-card-title {
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 600;
  letter-spacing: 0.7px;
}
.notify-box ion-card-subtitle {
  display: flex;
}
.notify-box ion-card-subtitle .recent-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.notify-box ion-card-subtitle .recent-time span {
  font-size: 12px;
  letter-spacing: 0.5px;
}
.notify-box ion-card-subtitle .recent-time .notify-avatr {
  width: 100px;
  height: 100px;
  margin-top: 7px;
}
.notify-box ion-icon {
  margin-right: 10px;
}
.notify-box .notify-subtitle {
  display: inline;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.alerts-table {
  width: 100%;
  th,
  td {
    padding: 0.4em 0.2em;
  }
  th {
    background-color: $light-gray;
  }
  tr {
    margin-bottom: 1em;
    border-bottom: 1px solid $light-gray;
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
  }
  & td:first-of-type {
    width: 100%;
    grid-column: 1/-1;
  }
  .title {
    font-size: 18px;
  }
  .meta {
    font-size: 13px;
    color: #4B545F;
  }
}
@media screen and (min-width: $breakpoint-md) {
  .alerts-table {
    th,
    td {
      font-size: 0.85rem;
    }
  }
  .sidebar .alerts-table {
    th,
    td {
      font-size: 0.75rem;
    }
  }
}

td.rt,
th.rt {
  text-align: right;
}
td.ctr,
th.ctr {
  text-align: center;
}
