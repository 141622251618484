.moreIcon {
  margin-top: -8px;
  display: block;
  color: #fff;
}
.moreLabel {
  display: block;
  font-size: 10px;
  color: #fff;
  text-transform: initial;
}