// login button, for display issue in iOS
html input[type=button],
input[type=reset],
input[type=submit],
button {
  -webkit-appearance: inherit !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: inherit !important;
}

// iphone notch
//body{
//  margin-top: constant(safe-area-inset-top);
//  margin-top: env(safe-area-inset-top);
//}
//body {
//  margin-top: constant(safe-area-inset-top);
//  margin-top: env(safe-area-inset-top);
//  margin-bottom: constant(safe-area-inset-bottom);
//  margin-bottom: env(safe-area-inset-bottom);
//}

a,
.link {
  color: var(--mc-link);
  &:hover,
  &:focus {
    color: var(--mc-link-hover);
  }
}
