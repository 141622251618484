// links
a {
  transition: all .2s ease;
}

// forms
label {
  font-weight: 600;
}

// lists
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

// tables
.table {
  th,
  td {
    padding: .4em .2em;
  }
  th {
    background-color: $light-gray;
  }
}
@media screen and (min-width: $breakpoint-md) {
  .table {
    th,
    td {
      font-size: .85rem;
    }
  }
  .sidebar .table {
    th,
    td {
      font-size: .75rem;
    }
  }
}

.table tr:nth-child(even) {
  background-color: $table-alt-row-bg;
}

td.rt,
th.rt {
  text-align: right;
}
td.ctr,
th.ctr {
  text-align: center;
}
